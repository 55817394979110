<template>
  <div
    v-if="props.queryResponse.length"
  >
    <Table
      :headers="[
        {
          text: t('name'),
          field: 'name',
        },
        {
          text: t('value'),
          field: 'value',
        },
        {
          text: t('profit'),
          field: 'profit',
        },
        {
          text: t('gross_profit'),
          field: 'gross_profit',
        },
        {
          text: t('avg_price'),
          field: 'average_price',
        },
        {
          text: t('cost'),
          field: 'cost',
        },
        {
          text: t('sales_value'),
          field: 'sales_value',
        },
        {
          text: t('quantity'),
          field: 'quantity',
        },
      ]"
      :data="props.queryResponse"
      @close-results="closeResults"
    />
  </div>
</template>

<script setup>
import { tAiQuery as t } from '@sales-i/utils';

import Table from '@/components/Table/Table.vue';

const props = defineProps({
  queryResponse: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['closeResults']);

const closeResults = () => {
  emit('closeResults');
};

</script>