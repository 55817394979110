<template>
  <div class="lottery">
    <div class="numbers">
      <span
        v-for="number in getLotteryNumbers()"
        :key="number"
        :class="getNumberColour(number)"
      >
        {{ number < 10 ? `0${number}` : number }}
      </span>
    </div>
    <h3>{{ t('it_could_be_you') }}</h3>
  </div>
</template>

<script setup>
import { tAiQuery as t } from '@sales-i/utils';

let numbers = [];

const getLotteryNumbers = (max = 59) => {
  // Get 6 numbers, they must be unique, from 1-59
  if (numbers.length === 6) {
    return numbers;
  }
  
  let rando = (Math.random() * max).toFixed();
  if (numbers.includes(rando) === false) {
    numbers.push(rando);
  }

  return getLotteryNumbers(max);
};

const getNumberColour = (number) => {
  if (number > 10 && number <= 20) {
    return 'green';
  }

  if (number > 20 && number <= 30) {
    return 'blue';
  }

  if (number > 30 && number <= 40) {
    return 'yellow';
  }

  if (number > 40) {
    return 'white';
  }

  return 'pink';
};

</script>

<style lang="scss" scoped>
.lottery {
  text-align: center;
  padding: var(--spacing-4);

  span {
    display: inline-block;
    border: 1px solid var(--colour-utility-black);
    border-radius: 100px;
    padding: var(--spacing-2) var(--spacing-3);
    font-size: var(--font-size-2);
    margin-right: var(--spacing-2);
    text-decoration: underline;

    &.pink {
      background-color: var(--colour-data-mandy-light);
    }
    &.yellow {
      background-color: var(--colour-data-barberry-light)
    }
    &.blue {
      background-color: var(--colour-brand-puerto-rico);
    }
    &.green {
      background-color: var(--colour-brand-de-york);
    }
  }

  h3 {
    margin-top: var(--spacing-2);
  }
}
</style>