<template>
  <div>
    <h3>{{ getFunVerbiage() }}</h3>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { tAiQuery as t } from '@sales-i/utils';

const store = useStore();

const getFunQuestion = computed(() => store.getters['conversation/getFunQuestion']);

const getFunVerbiage = () => {
  let x = 0;
  let verbiage = '';
  for (x; x < getFunQuestion.value.entities.length; x++) {
    const _ref = getFunQuestion.value.entities[x];

    if (_ref.text.toLowerCase().includes('santa') || _ref.text.toLowerCase().includes('father')) {
      verbiage = t('of_course_i_believe_in_santa_where_do_you_think_all_the_presents_come_from_definitely_not_the_rainforest_company');
    }
    if (_ref.text.toLowerCase().includes('easter')) {
      verbiage = t('of_course_i_believe_in_the_easter_bunny_where_do_you_think_all_the_eggs_come_from_definitely_not_some_chocolate_factory');
    }
  }

  return verbiage;
};

</script>

<style lang="scss" scoped>
h3 {
  text-align: center;
  margin: var(--spacing-2) auto;
  width: 80%;
}
</style>