<template>
  <CustomModal
    v-if="modalToDisplay"
    :title="modalTitle"
    :max-width="400"
    @close-modal="popModal"
  >
    <div>
      <div class="confirm-message-wrapper text-centre">
        <h1 class="fw-sbld text-2">
          {{ strings.parseHtmlEntities(modalToDisplay.title) }}
        </h1>
        <p>{{ strings.parseHtmlEntities(modalToDisplay.message) }}</p>
      </div>
    </div>

    <template #footer>
      <div class="buttons-container">
        <CustomButton
          label="OK"
          small
          class="ok"
          @click="popModal"
        />
      </div>
    </template>
  </CustomModal>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { CustomModal, CustomButton } from '@sales-i/dsv3';
import { REMOVE_BY_INDEX } from '@/shared/store/actionType';
import { tAiQuery as t, strings } from '@sales-i/utils';

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['popModal']);
const store = useStore();
// TODO: this should be popMessageFromStack like in fe-crm??
const removeByIndex = (index) => store.dispatch(`modalQueue/${REMOVE_BY_INDEX}`, index);

const modalToDisplay = computed(() => {
  return props.items.length > 0 ? props.items[0] : false;
});

const modalTitle = computed(() => {
  return t('error');
});

const popModal = () => {
  if (modalToDisplay.value.closeFunction) {
    modalToDisplay.value.closeFunction();
  }
  emit('popModal');
  removeByIndex(0);
};
</script>

<style lang="scss">
.confirm-message-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-3) var(--spacing-4);
}

.buttons-container {
  display: flex;
  gap: var(--spacing-2);
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
</style>